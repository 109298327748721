import React from "react"
import { Link, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Social from "../../components/social.js"
import Layout from "../../components/LayoutAlt.js"
import { FaArrowAltCircleLeft, FaUtensils } from "react-icons/fa"


const Breakfast = ({ data }) => {


  return (
    <Layout>

     <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="Begin your day with a healthy meal option. Power up in the morning." />
        <title>ReaCH | Breakfast</title>
        <link rel="canonical" href="https://reachun.org/diet/breakfast" />
      </Helmet>

      <section className="sm:w-3/4 sm:ml-1/8  mt-20 mb-20 xs:p-4 sm:p-0">
        <h1 className="font-montserrat font-bold text-xl text-blue-dark text-center mb-2">ReaCh HD</h1>
        <h2 className="font-playfairdisplay  text-4xl sm:text-6xl text-blue-dark text-center mb-4">Boost Your Energy Levels</h2>
        <p className="font-opensans text-gray-700 text-justify md:text-lg">Breakfast is often called ‘the most important meal of the day’, and for good reason. As the name suggests, breakfast breaks the overnight fasting period. It replenishes your supply of glucose to boost your energy levels and alertness, while also providing other essential nutrients required for good health . <span className="text-sm text-orange-dark font-bold underline"><a href="https://www.betterhealth.vic.gov.au/health/HealthyLiving/breakfast"> Breakfast - Better Health</a></span></p>

        <Link to="/diet">
          <section className="font-opensans  text-white  bg-blue-light my-10 py-1 pl-4 
                rounded-r-xl flex items-center">
            <FaArrowAltCircleLeft size="20" className="mr-2 cursor-pointer" />
            <p>diet/breakfast</p>
          </section>
        </Link>

        <h2 className="font-montserrat font-bold text-xl text-blue-dark mb-2">Featured</h2>


        {data.allStrapiRecipes.edges.map(food => (
          <div className={food.node.featured ? "block" : "hidden"} key="food.node.id">
            <div className="grid grid-cols-1 gap-10 xl:grid-cols-2 xl:gap-12">
              <div>
                <img 
                srcSet={food.node.imgix_path}
                src={food.node.src_path} alt="Food" 
                className="w-full xs:h-64 sm:h-80 lg:h-100 object-cover " />
              </div>
              <div className="">
                <p className="font-playfairdisplay font-bold text-4xl 2xl:text-5xl text-blue-dark mb-8 ">{food.node.title}</p>
                <div className="mb-8 ">
                  <p className="w-3/4 xl:w-full ">{food.node.desc}</p>
                  <div className="flex items-center font-opensans text-xs sm:text-sm my-2 text-gray-500 space-x-2">
                    <img src={food.node.chef.image.formats.thumbnail.url} alt="Chef" className="w-10 h-10 rounded-full border-2" />
                    <p className="">{food.node.chef.name}</p>
                    <p className="">{food.node.date}</p>
                  </div>
                </div>
                <p className="font-opensans font-thin text-2xl sm:text-4xl text-gray-700">{food.node.ingredients}<span className="text-xs sm:text-base"> ingredients</span> | {food.node.duration} <span className="text-xs sm:text-base">Min.</span> | {food.node.calories} <span className="text-xs sm:text-base">Approx.Cal.</span></p>

                <Link to={`/diet/${food.node.category}/${food.node.slug}`}>
                  <div className="flex items-center mt-12 text-white sm:text-xl font-opensans">
                    <div className="bg-orange-dark px-4 py-2 sm:px-6 sm:py-4 rounded-l-lg font-bold shadow-xl cursor-pointer">
                      <p>Serving : {food.node.serving}</p>
                    </div>
                    <div className="flex items-center space-x-2 bg-orange-light px-4 py-2 sm:px-6 sm:py-4 rounded-r-lg shadow-xl cursor-pointer">
                      <p>Try Recipe</p>
                      <p><FaUtensils /></p>
                    </div>
                  </div>
                </Link>

              </div>
            </div>
          </div>
        ))}
      </section>

      <section className="sm:w-3/4 sm:ml-1/8  mt-20 mb-20 xs:p-4 sm:p-0">
        <h2 className="font-montserrat font-bold text-xl mb-4">Meal options for Breakfast</h2>
        <div className="grid md:grid-cols-2 md:gap-4 xl:grid-cols-3 2xl:grid-cols-4 ">
          {data.allStrapiRecipes.edges.map(food => (
            <div className={food.node.featured ? "hidden" : "block"} key={food.node.id}>
              <div className=" bg-gray-50 shadow-md hover:shadow-xl transition duration-500 ease-in-out transform hover:scale-100 hover:-translate-y-1 mt-4">
                <img 
                srcSet={food.node.imgix_path}
                src={food.node.src_img} alt="Food" 
                className=" w-full h-48 object-cover " />
                <p className="font-playfairdisplay font-bold text-gray-500 text-xl pl-2 mt-4">{food.node.title}</p>
                <p className="font-opensans font-thin text-sm text-gray-700 pl-2 mt-4">{food.node.ingredients}<span className="text-sm"> ingredients</span> | {food.node.duration} <span className="text-xs">Min.</span> | {food.node.calories} <span className="text-xs">Approx.Cal.</span></p>
                <div className="flex items-center text-white text-lg font-opensans mt-6">

                  <Link to={`/diet/${food.node.category}/${food.node.slug}`}>
                    <div className="flex items-center space-x-2 bg-orange-light px-4 py-2 rounded-r-lg shadow-xl cursor-pointer">
                      <p>Try Recipe</p>
                      <p><FaUtensils /></p>
                    </div>
                  </Link>

                </div>
                <p className="font-opensans text-xs text-gray-500 pl-3 mt-6 pb-8">by {food.node.chef.name} | {food.node.date}</p>
              </div>
            </div>
          ))}
        </div>
      </section>

      <section className="sm:w-3/4 sm:ml-1/8  mt-20 mb-20 xs:p-4 sm:p-0">
               <Link to="/diet">
                    <button className="font-opensans font-bold text-white bg-orange-dark ring ring-orange-light ring-offset-2 rounded-sm py-1 px-8">
                     Back
                    </button>
                    </Link>
               </section>
     <Social />
    </Layout>
  )
}
export const query = graphql`
  {
    allStrapiRecipes(
      filter: {category: {eq: "breakfast"}}
      sort: {fields: date, order: DESC}
    ) {
      edges {
        node {
          title
          desc
          id
          chef {
            name
            slug
            image{
              formats{
                thumbnail{
                  url
                }
              }
            }
          }
          date(formatString: "LL")
          duration
          calories
          ingredients
          featured
          serving
          slug
          category
          imgix_path
          src_img
        }
      }
    }
  }
`


export default Breakfast